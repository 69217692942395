import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 3000.000000 3000.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,3000.000000) scale(0.100000,-0.100000)">
<path d="M14909 24156 c-2 -2 -80 -7 -174 -10 -199 -8 -234 -11 -380 -38 -60
-11 -139 -24 -174 -29 -125 -17 -512 -101 -696 -151 -125 -34 -238 -72 -675
-230 -69 -25 -215 -89 -325 -143 -110 -54 -218 -106 -240 -116 -46 -21 -210
-112 -267 -147 -15 -9 -65 -41 -112 -70 -47 -29 -111 -72 -143 -96 -32 -23
-105 -75 -163 -115 -116 -79 -251 -184 -358 -276 -38 -33 -85 -71 -103 -85
-69 -52 -176 -142 -233 -198 -59 -56 -146 -157 -146 -169 0 -3 12 -3 28 0 19
4 34 -1 49 -15 19 -18 34 -20 123 -19 55 0 131 5 168 11 136 19 230 21 264 6
45 -19 159 -126 182 -169 33 -64 20 -89 -112 -217 -161 -157 -202 -194 -202
-181 0 16 -17 13 -40 -6 -11 -10 -24 -18 -28 -18 -19 0 -50 -34 -63 -68 -7
-22 -21 -43 -31 -48 -10 -5 -26 -39 -37 -77 -11 -37 -36 -89 -56 -117 -19 -27
-65 -93 -102 -145 -80 -114 -270 -416 -324 -514 -46 -85 -229 -456 -229 -465
0 -3 -11 -27 -24 -52 -14 -25 -33 -70 -44 -100 -11 -30 -37 -99 -57 -154 -20
-55 -45 -127 -55 -160 -33 -106 -51 -159 -71 -205 -32 -73 -76 -253 -119 -490
-46 -248 -57 -300 -63 -306 -4 -4 -7 2 -7 14 0 12 -3 22 -8 22 -4 0 -16 16
-26 35 -17 31 -43 58 -146 150 -14 12 -38 57 -54 99 -37 97 -80 166 -104 166
-43 0 -168 -52 -202 -84 -19 -18 -50 -39 -68 -46 -41 -16 -44 -22 -48 -84 -2
-28 -7 -67 -10 -86 -4 -19 -8 -44 -10 -55 -1 -11 -9 -40 -19 -65 -9 -25 -20
-70 -25 -100 -17 -107 -25 -132 -57 -183 -29 -45 -68 -169 -82 -257 -29 -176
12 -943 64 -1231 41 -225 69 -362 92 -449 14 -52 34 -131 45 -175 42 -169 224
-698 267 -775 5 -9 34 -75 64 -146 120 -283 275 -574 445 -839 132 -206 191
-288 381 -524 42 -52 98 -124 126 -160 68 -91 474 -497 610 -610 22 -18 65
-54 95 -81 30 -26 89 -76 130 -112 41 -35 111 -91 155 -124 44 -32 94 -69 110
-81 75 -57 288 -193 360 -232 44 -24 105 -58 136 -77 31 -19 59 -34 63 -34 3
0 34 -17 68 -38 131 -79 412 -210 633 -295 72 -27 153 -58 180 -69 51 -20 191
-70 320 -115 110 -38 643 -169 825 -202 234 -42 304 -50 580 -66 319 -18 530
-18 825 0 553 34 696 60 1295 236 372 109 934 349 1200 513 58 35 148 90 200
122 206 125 492 322 560 386 29 28 88 79 223 193 124 105 539 521 652 655 49
58 99 116 111 130 95 110 159 190 226 284 92 130 172 245 183 264 6 10 23 35
38 57 65 93 235 392 304 535 41 85 88 175 105 199 69 103 30 113 -422 106
-179 -2 -363 -9 -410 -14 -47 -6 -164 -13 -260 -17 -205 -7 -194 -2 -289 -144
-31 -46 -87 -118 -126 -160 -38 -41 -93 -104 -122 -140 -28 -36 -60 -72 -70
-81 -24 -21 -65 -68 -134 -154 -85 -106 -292 -309 -404 -397 -199 -156 -320
-254 -379 -309 -34 -31 -79 -71 -101 -91 -55 -48 -233 -181 -270 -202 -16 -9
-61 -37 -100 -61 -180 -116 -389 -227 -610 -325 -76 -34 -240 -86 -330 -105
-44 -10 -95 -22 -112 -27 -137 -41 -447 -92 -653 -108 -74 -6 -141 -13 -149
-16 -34 -12 -405 -29 -626 -29 -275 0 -477 10 -585 30 -41 8 -122 21 -180 30
-104 16 -195 32 -340 60 -41 7 -104 19 -140 25 -36 7 -83 16 -105 20 -22 5
-74 15 -115 23 -41 7 -88 21 -105 29 -31 16 -148 62 -300 118 -79 30 -375 169
-430 203 -62 38 -239 161 -325 227 -36 27 -87 62 -115 78 -27 17 -77 53 -110
81 -33 28 -82 69 -110 92 -27 22 -72 62 -100 88 -27 25 -97 88 -155 138 -177
154 -426 418 -578 613 -45 58 -87 111 -93 118 -27 32 -279 408 -352 525 -93
148 -261 512 -330 717 -36 104 -142 454 -157 515 -48 198 -95 447 -127 675
-15 103 -18 192 -17 505 0 331 3 402 22 549 13 93 26 187 31 210 5 23 17 77
26 121 9 44 23 108 31 142 8 34 14 72 14 85 0 61 70 315 140 508 69 188 110
303 119 330 22 68 239 504 301 605 210 344 260 416 403 583 93 108 171 171
246 198 32 11 57 27 62 39 5 11 25 83 44 160 58 231 176 514 283 680 24 39 51
81 60 95 22 36 123 151 216 248 336 348 750 547 1209 582 252 19 960 30 1842
28 1310 -2 1480 -10 1761 -83 309 -80 529 -187 784 -383 213 -163 357 -344
490 -617 85 -174 111 -248 160 -447 21 -87 41 -162 44 -167 3 -4 29 -11 57
-15 29 -4 69 -13 91 -20 92 -32 360 -239 533 -411 177 -176 251 -307 335 -590
28 -93 62 -231 76 -305 30 -168 70 -314 124 -452 65 -168 -17 -149 635 -146
l560 3 -1 53 c-1 30 -7 72 -12 95 -6 23 -27 110 -47 192 -20 83 -42 155 -48
161 -7 7 -67 21 -134 33 -249 44 -393 103 -565 231 -275 204 -482 472 -589
761 -17 46 -34 98 -39 114 -5 17 -18 59 -30 95 -79 245 -83 642 -8 893 11 37
24 70 29 73 12 7 12 74 0 74 -5 0 -38 28 -73 63 -35 35 -96 90 -137 123 -41
32 -95 76 -120 97 -228 187 -666 484 -970 659 -19 11 -60 36 -90 55 -59 38
-136 81 -211 118 -138 69 -396 192 -465 222 -157 68 -567 195 -785 243 -19 4
-80 19 -135 33 -391 98 -582 131 -890 152 -197 13 -705 21 -716 11z m-5095
-6015 c14 -268 39 -585 53 -681 39 -272 59 -457 52 -484 -14 -49 -37 -75 -79
-87 -30 -9 -50 -25 -76 -60 -19 -26 -44 -51 -54 -54 -56 -18 -211 121 -272
245 -13 25 -23 47 -24 50 0 3 -9 23 -19 45 -22 51 -44 124 -51 173 -12 78 -19
239 -13 282 4 25 10 70 13 101 8 71 57 242 84 295 11 22 50 71 85 108 51 54
69 67 85 62 25 -8 78 27 133 88 35 39 43 44 59 34 15 -10 19 -30 24 -117z m23
-1727 c15 -20 34 -41 42 -48 55 -44 79 -78 85 -117 4 -24 13 -57 21 -73 8 -17
21 -50 29 -73 8 -24 20 -48 26 -55 6 -7 30 -42 53 -78 23 -35 62 -79 87 -98
82 -62 124 -97 144 -121 32 -36 76 -80 89 -88 12 -8 65 -77 117 -154 14 -20
52 -76 85 -124 33 -48 85 -125 115 -171 243 -368 362 -514 664 -815 130 -130
266 -261 302 -290 36 -30 100 -83 142 -117 42 -35 106 -85 142 -110 36 -25 83
-58 105 -74 68 -48 139 -94 157 -101 9 -3 58 -37 110 -74 133 -98 309 -210
406 -257 134 -66 140 -70 144 -91 9 -44 -13 -84 -77 -143 -56 -51 -78 -64
-150 -87 -46 -14 -96 -36 -110 -47 -30 -23 -95 -41 -95 -26 0 5 -4 7 -9 3 -5
-3 -12 -1 -16 5 -3 5 -15 10 -25 10 -10 0 -38 9 -62 20 -43 20 -54 26 -118 67
-19 12 -52 31 -73 41 -22 10 -65 41 -97 68 -58 48 -67 63 -153 233 -16 31 -33
58 -38 59 -5 2 -9 10 -9 18 0 8 -3 14 -8 14 -4 0 -33 24 -64 53 -30 28 -80 65
-109 81 -106 58 -164 86 -184 86 -11 1 -45 14 -75 30 -30 17 -63 30 -73 30
-11 0 -31 8 -45 19 -15 10 -47 28 -71 41 -44 22 -141 112 -141 131 0 6 -37 47
-82 90 -46 43 -102 97 -125 121 -24 23 -45 39 -48 36 -6 -6 -140 124 -180 175
-11 14 -43 51 -72 83 -70 80 -217 287 -288 407 -32 53 -61 97 -66 97 -5 0 -15
13 -24 30 -8 16 -38 59 -65 96 -62 83 -166 286 -191 372 -10 36 -22 68 -27 71
-5 3 -12 13 -15 21 -4 8 -16 29 -26 45 -37 57 -60 107 -55 120 3 7 1 16 -5 20
-13 7 -15 95 -3 95 14 0 5 97 -10 119 -8 12 -18 18 -21 14 -4 -3 -7 3 -7 15 0
14 -6 22 -18 22 -35 0 -112 89 -112 129 0 16 -5 31 -10 33 -13 4 27 84 40 81
6 -2 10 4 10 12 0 8 5 15 10 15 6 0 10 7 10 15 0 8 5 15 11 15 5 0 8 4 5 9 -3
5 1 14 9 21 8 7 15 18 15 24 0 16 29 56 40 56 5 0 22 -16 37 -36z m9001 -2226
c62 -5 63 -233 1 -319 -10 -15 -19 -31 -19 -37 0 -5 -6 -15 -14 -21 -7 -6 -24
-42 -36 -79 -12 -37 -32 -87 -45 -112 -14 -25 -31 -58 -39 -75 -8 -16 -40 -52
-72 -80 -31 -27 -66 -59 -78 -70 -12 -12 -34 -29 -51 -40 -16 -10 -36 -27 -43
-37 -7 -10 -19 -18 -25 -18 -7 0 -30 -14 -51 -30 -22 -17 -45 -30 -52 -30 -6
0 -17 -7 -25 -16 -7 -8 -33 -27 -58 -40 -68 -37 -153 -88 -213 -127 -29 -20
-101 -60 -158 -89 -101 -51 -108 -53 -180 -52 -41 1 -106 6 -145 10 -82 10
-297 11 -340 1 -54 -12 -139 -22 -185 -21 -55 1 -217 14 -245 21 -20 4 -151 6
-170 3 -5 -1 -53 -1 -105 0 l-95 2 65 17 c141 38 228 64 295 88 112 39 259 96
271 105 6 4 51 24 100 44 49 19 93 40 99 45 5 5 15 9 23 9 30 0 427 188 470
223 15 12 61 38 132 76 114 60 283 186 542 406 71 61 144 117 161 125 18 8 35
18 38 23 3 4 13 5 22 1 10 -3 17 -1 17 5 0 6 7 11 15 11 8 0 30 13 48 30 48
42 88 61 112 54 11 -3 26 -6 33 -6z"/>
<path d="M14869 22786 c-2 -2 -260 -9 -574 -16 -628 -13 -906 -32 -1030 -70
-155 -49 -435 -180 -530 -248 -76 -55 -190 -168 -264 -262 -66 -84 -161 -222
-161 -234 0 -4 -11 -24 -24 -44 -83 -132 -152 -385 -181 -667 -8 -78 -12
-4841 -4 -5420 6 -505 14 -578 84 -789 53 -162 79 -222 152 -345 100 -169 175
-264 318 -402 140 -134 335 -268 526 -363 120 -60 156 -76 294 -127 171 -64
302 -78 845 -89 250 -5 559 -12 685 -16 347 -11 1387 20 1572 47 146 20 430
133 552 218 14 10 31 20 36 22 6 2 49 30 96 62 116 77 196 151 284 263 41 51
82 101 93 111 73 72 168 229 299 498 30 61 58 117 62 125 16 29 41 176 51 295
6 66 15 743 21 1505 10 1397 6 2533 -11 2953 -5 125 -10 418 -11 650 -1 232
-5 463 -10 512 -5 50 -13 152 -18 229 -10 131 -9 141 11 185 53 117 -22 396
-180 671 -47 81 -180 255 -230 301 -197 178 -388 291 -607 357 -180 54 -250
60 -735 67 -245 3 -521 10 -615 15 -168 9 -788 14 -796 6z m938 -522 c31 -6
82 -63 93 -104 20 -74 -36 -175 -113 -205 -41 -15 -99 -17 -663 -13 -672 4
-667 4 -715 60 -23 29 -49 110 -49 157 0 48 32 99 67 107 15 3 33 7 38 9 15 5
1317 -6 1342 -11z m959 -164 c95 -27 220 -80 298 -127 154 -93 295 -279 376
-494 51 -138 49 -55 43 -2028 -5 -1382 -2 -2034 8 -2636 17 -943 17 -943 -13
-1004 -26 -55 -246 -284 -321 -335 -104 -71 -83 -70 -1027 -70 -465 -1 -1315
0 -1890 2 -991 2 -1048 3 -1108 21 -74 23 -111 49 -237 169 -112 107 -156 168
-187 260 l-22 67 -7 2650 c-5 2137 -4 2664 6 2720 15 80 79 256 120 330 73
129 200 263 317 334 66 39 194 99 247 115 70 21 267 48 316 44 l40 -3 7 -75
c16 -173 62 -267 188 -383 81 -75 182 -139 269 -169 52 -19 96 -21 601 -30
443 -8 610 -7 891 5 336 14 347 16 415 42 38 16 89 42 114 58 76 52 213 197
247 262 31 62 63 182 63 241 0 18 4 44 9 57 l10 24 75 -13 c42 -7 110 -23 152
-34z m-1519 -7261 c83 -29 174 -112 192 -175 40 -139 19 -247 -62 -328 -77
-77 -128 -97 -243 -97 -104 -1 -140 12 -230 80 -79 59 -102 101 -115 210 -18
153 8 216 116 280 109 66 212 75 342 30z"/>
<path d="M13664 20656 c-118 -44 -206 -113 -252 -201 -24 -45 -27 -60 -27
-155 0 -98 2 -108 29 -153 38 -65 144 -156 214 -185 67 -28 151 -37 207 -24
39 9 39 10 15 20 -20 8 -22 11 -9 16 12 5 15 23 14 92 -1 59 3 88 11 91 16 6
3 52 -26 91 -18 24 -22 39 -17 68 8 49 39 79 112 109 33 13 61 30 63 37 2 7
16 41 32 74 27 59 50 82 114 114 l29 15 -44 7 c-24 3 -125 7 -224 7 -163 0
-186 -2 -241 -23z"/>
<path d="M14548 19968 c-7 -4 162 -8 376 -8 214 0 386 3 382 8 -10 10 -742 11
-758 0z"/>
<path d="M13656 19343 c-51 -15 -139 -70 -189 -118 -73 -69 -107 -174 -87
-269 12 -58 58 -154 87 -183 50 -49 110 -87 166 -105 58 -18 79 -19 297 -12
129 4 773 8 1430 9 l1195 1 50 28 c42 24 95 70 95 83 0 2 -12 0 -27 -3 -21 -6
-25 -4 -20 9 3 8 -1 21 -9 27 -8 7 -14 17 -14 22 0 5 -14 42 -31 81 -33 74
-32 69 -12 211 3 21 -1 56 -8 78 -16 51 -4 74 37 70 16 -2 33 -8 38 -13 6 -5
19 -9 30 -9 18 0 18 2 -5 26 -13 15 -44 36 -69 48 -43 20 -67 21 -475 27 -236
4 -880 7 -1430 7 -865 1 -1007 -1 -1049 -15z"/>
<path d="M13620 18047 c-79 -31 -189 -138 -225 -219 -36 -80 -39 -179 -8 -248
49 -110 154 -206 263 -240 65 -21 70 -21 710 -9 424 8 795 9 1085 3 242 -4
586 -8 765 -9 l325 0 57 29 c86 43 151 117 173 196 6 22 3 20 -25 -13 -17 -20
-36 -35 -41 -31 -5 3 -7 9 -4 13 8 14 -157 161 -180 161 -8 0 -29 13 -47 29
-47 41 -142 63 -288 66 -73 2 -380 -20 -430 -30 -52 -11 -250 -6 -380 8 -165
19 -430 34 -730 42 -228 6 -447 24 -435 37 8 7 -50 16 -145 22 -47 3 -89 14
-150 41 -100 43 -152 74 -145 85 3 5 0 10 -6 12 -7 2 -9 13 -5 25 9 29 39 33
255 34 102 1 211 4 241 9 33 4 -71 7 -260 8 -300 1 -318 0 -370 -21z"/>
<path d="M14075 16750 l-390 -5 -69 -34 c-46 -22 -94 -58 -142 -105 -61 -61
-75 -80 -89 -130 -35 -121 1 -244 99 -341 108 -105 205 -129 446 -112 74 5
686 9 1360 9 l1225 0 54 26 c65 31 200 160 225 216 11 24 18 63 18 105 1 59
-4 77 -36 137 -64 122 -158 204 -256 223 -61 12 -1777 20 -2445 11z"/>
<path d="M21733 22019 c-23 -16 -90 -77 -149 -135 -59 -59 -155 -148 -213
-199 -224 -197 -554 -550 -569 -610 -10 -36 -41 -41 -58 -8 -13 27 -85 103
-254 268 -137 135 -184 188 -199 228 -6 15 -15 27 -21 27 -6 0 -8 -4 -5 -10 9
-15 -19 -12 -41 5 -10 8 -31 15 -45 15 -15 0 -42 6 -60 14 -39 16 -184 7 -257
-15 l-44 -14 6 -46 c8 -60 41 -189 66 -257 61 -163 207 -378 332 -484 243
-208 482 -292 833 -292 122 0 164 4 225 21 88 25 209 67 265 93 22 10 60 28
85 39 64 29 94 48 186 118 196 150 321 355 406 663 29 107 47 283 34 334 -3
14 -13 26 -21 26 -8 0 -15 5 -15 10 0 6 -6 10 -13 10 -8 0 -22 9 -32 20 -18
20 -193 110 -214 110 -15 0 -120 51 -127 61 -3 5 -14 9 -26 9 -14 0 -18 5 -14
15 8 23 -14 17 -61 -16z"/>
<path d="M11613 20863 c-10 -16 -61 -80 -114 -144 -53 -64 -105 -129 -115
-145 -11 -16 -43 -62 -72 -103 -65 -91 -253 -477 -292 -601 -4 -14 -29 -83
-55 -155 -98 -271 -149 -455 -180 -650 -9 -54 -25 -140 -36 -193 -42 -205 -60
-388 -66 -687 -10 -481 29 -798 162 -1325 35 -138 43 -163 112 -355 76 -210
150 -375 233 -520 13 -22 55 -98 93 -170 96 -178 406 -679 415 -671 2 2 -6 53
-17 112 -19 104 -20 176 -31 1479 -5 754 -8 1997 -6 2763 3 849 1 1392 -4
1392 -5 0 -17 -12 -27 -27z"/>
<path d="M18572 20471 c-76 -13 -75 -9 -63 -257 26 -521 32 -884 32 -1764 -1
-514 2 -1239 5 -1610 7 -713 1 -951 -36 -1415 -18 -231 -27 -315 -46 -420 l-5
-30 23 29 c13 16 48 59 78 96 30 37 91 116 135 176 72 99 198 236 267 291 14
12 82 63 149 115 68 51 149 119 180 151 l56 57 72 0 c84 0 84 -2 -4 100 -58
67 -131 205 -156 295 -10 34 -14 102 -13 215 1 146 4 175 27 254 15 52 33 94
43 99 28 16 111 110 126 143 13 28 -11 74 -73 139 -73 77 -119 135 -133 169
-22 55 -38 228 -30 348 12 205 65 345 134 359 19 4 45 19 57 33 29 34 23 50
-61 159 -160 207 -213 428 -147 614 41 115 61 144 160 226 47 39 89 76 93 82
16 22 -2 30 -77 35 -102 7 -110 17 -125 160 -6 58 -13 106 -15 108 -5 6 -76
21 -125 27 -66 9 -79 14 -147 57 -84 53 -129 105 -149 174 -18 63 -15 89 37
339 28 136 22 269 -14 342 -33 65 -78 103 -122 103 -18 0 -45 2 -61 4 -16 2
-48 1 -72 -3z"/>
<path d="M21395 19055 c35 -16 81 -21 54 -5 -9 5 -31 11 -50 14 l-34 5 30 -14z"/>
<path d="M19840 18620 c-19 -4 -52 -15 -72 -24 -21 -9 -46 -16 -56 -16 -11 0
-39 -16 -63 -35 -24 -19 -52 -35 -61 -35 -29 0 -21 -24 26 -87 72 -94 132
-134 231 -152 39 -7 296 -11 764 -11 581 0 721 3 791 15 136 24 172 50 229
164 38 76 46 103 29 99 -7 -2 -39 6 -70 16 -54 19 -70 19 -410 8 -194 -7 -380
-15 -413 -19 -33 -5 -139 -8 -235 -8 -174 0 -294 13 -400 42 -30 9 -56 16 -57
15 -2 0 -17 3 -35 9 -18 5 -60 12 -93 15 -33 4 -62 7 -65 8 -3 2 -21 -1 -40
-4z"/>
<path d="M19960 17900 c-164 -8 -207 -22 -281 -93 -100 -96 -123 -211 -70
-355 33 -91 61 -138 125 -209 81 -90 69 -88 570 -75 237 7 587 12 778 12 346
0 348 0 402 24 72 32 184 139 221 211 23 46 28 69 28 134 1 102 -30 168 -119
256 -101 100 -51 94 -839 98 -374 1 -741 0 -815 -3z"/>
<path d="M20480 16809 c-63 -5 -232 -13 -375 -17 -251 -9 -262 -10 -310 -35
-103 -52 -152 -139 -162 -289 -5 -84 -3 -98 17 -138 66 -132 176 -207 404
-276 l78 -24 539 0 c591 0 539 -5 677 64 135 67 192 130 238 267 31 93 31 152
-3 236 -42 106 -90 158 -188 201 -37 17 -739 25 -915 11z"/>
<path d="M25161 11370 c-67 -14 -180 -72 -233 -119 -56 -50 -108 -126 -138
-206 -33 -83 -33 -242 -1 -329 72 -200 263 -336 471 -336 208 0 399 136 471
336 32 87 32 246 -1 329 -76 198 -241 320 -445 330 -44 2 -100 0 -124 -5z
m245 -186 c68 -42 107 -117 102 -196 -12 -168 -191 -261 -334 -172 -68 42
-107 117 -102 196 12 168 191 261 334 172z"/>
<path d="M23360 10345 c0 -303 -4 -495 -9 -495 -6 0 -16 8 -23 19 -30 43 -129
116 -213 156 -113 55 -210 75 -362 75 -257 0 -460 -77 -635 -238 -390 -361
-412 -1184 -44 -1592 177 -196 421 -300 703 -300 197 0 370 64 500 184 39 36
74 66 77 66 3 0 6 -52 6 -115 l0 -115 385 0 385 0 0 1425 0 1425 -385 0 -385
0 0 -495z m-314 -866 c190 -35 304 -201 306 -444 0 -208 -78 -362 -220 -432
-50 -25 -68 -28 -152 -28 -88 0 -101 3 -162 33 -109 53 -183 163 -207 303 -48
286 81 532 298 569 66 11 71 11 137 -1z"/>
<path d="M7020 9340 l0 -1350 1120 0 1120 0 0 310 0 310 -720 0 -720 0 0 210
0 210 635 0 635 0 -2 308 -3 307 -632 3 -633 2 0 213 0 212 697 0 698 0 3 308
2 307 -1100 0 -1100 0 0 -1350z"/>
<path d="M15560 9340 l0 -1350 960 0 960 0 0 256 0 255 -37 80 -37 79 -528 0
-528 0 0 1015 0 1015 -395 0 -395 0 0 -1350z"/>
<path d="M10260 10109 c-233 -22 -518 -89 -735 -172 -66 -25 -89 -39 -87 -50
1 -9 41 -119 88 -244 47 -125 87 -234 90 -242 5 -17 19 -14 164 37 179 64 300
86 465 86 124 1 147 -2 198 -22 102 -41 152 -103 164 -202 l6 -48 -359 -5
c-374 -5 -410 -9 -544 -55 -190 -66 -315 -182 -376 -349 -27 -73 -29 -88 -29
-228 0 -163 7 -198 67 -324 44 -93 150 -197 254 -249 114 -57 179 -72 334 -79
162 -6 280 11 391 58 86 37 187 105 226 155 l28 34 5 -107 5 -108 383 -3 382
-2 0 719 c0 775 -1 794 -54 933 -44 117 -140 236 -256 316 -110 77 -284 131
-489 151 -116 12 -184 12 -321 0z m350 -1337 c0 -93 -2 -101 -30 -144 -81
-123 -265 -187 -412 -144 -184 53 -185 322 -1 372 31 8 118 13 246 13 l197 1
0 -98z"/>
<path d="M12305 10114 c-214 -30 -270 -43 -370 -86 -268 -116 -416 -330 -418
-603 -2 -259 131 -439 415 -561 46 -19 181 -64 302 -100 287 -85 346 -116 346
-182 0 -62 -58 -92 -178 -92 -194 0 -423 70 -656 202 -48 27 -89 48 -91 46
-16 -16 -235 -474 -231 -483 7 -19 162 -110 263 -155 100 -44 265 -94 386
-116 127 -24 430 -29 555 -10 367 56 615 251 678 533 21 97 15 252 -15 335
-77 219 -285 356 -720 474 -299 81 -387 143 -321 227 74 94 413 32 747 -137
l72 -36 38 69 c21 37 65 121 98 185 33 63 63 116 66 116 3 0 25 -46 49 -102
24 -57 82 -195 130 -308 48 -113 125 -293 170 -400 45 -107 129 -303 185 -435
168 -393 185 -434 185 -445 0 -23 -52 -111 -83 -143 -85 -84 -277 -78 -398 13
l-54 40 -20 -27 c-16 -22 -210 -333 -287 -459 l-19 -32 38 -29 c159 -124 360
-192 593 -200 234 -8 409 35 569 141 163 109 270 266 384 561 30 77 106 275
170 440 63 165 120 311 126 325 5 14 49 126 97 250 92 241 117 307 169 440
134 343 265 691 265 704 0 3 -173 6 -385 6 l-384 0 -21 -72 c-25 -83 -80 -267
-115 -378 -13 -41 -44 -145 -70 -230 -48 -157 -88 -292 -145 -479 -17 -57 -33
-102 -35 -100 -2 2 -33 87 -70 189 -36 102 -95 266 -131 365 -35 99 -106 297
-157 440 l-93 260 -401 3 -401 2 28 -67 c16 -37 27 -69 25 -71 -2 -2 -17 4
-34 12 -48 25 -218 85 -301 106 -159 39 -445 68 -545 54z"/>
<path d="M20650 10109 c-141 -13 -321 -45 -445 -80 -173 -47 -375 -121 -375
-137 0 -10 118 -337 172 -475 13 -34 9 -34 168 21 151 54 289 82 431 89 253
12 381 -67 401 -247 l3 -25 -355 -6 c-386 -7 -432 -13 -576 -68 -169 -65 -282
-168 -336 -306 -16 -39 -16 -35 -11 97 14 344 -77 598 -292 813 -138 138 -280
216 -491 270 -192 49 -479 58 -672 20 -266 -52 -504 -188 -650 -372 -196 -244
-268 -606 -188 -938 13 -54 22 -104 20 -110 -3 -6 7 -28 21 -49 14 -21 37 -61
51 -89 64 -127 196 -270 326 -354 408 -261 1060 -253 1459 18 158 107 289 280
355 469 l23 65 1 -100 c2 -269 120 -472 335 -576 113 -54 181 -70 330 -76 220
-9 380 32 520 132 34 24 76 60 93 79 17 20 34 36 37 36 3 0 5 -49 5 -110 l0
-110 380 0 380 0 0 723 c-1 786 -2 809 -57 942 -110 266 -352 415 -742 454
-116 12 -184 12 -321 0z m-1924 -659 c139 -68 214 -214 216 -416 2 -206 -75
-357 -219 -430 -52 -26 -68 -29 -153 -29 -82 0 -103 4 -152 27 -153 71 -232
219 -230 432 2 232 104 393 281 443 75 21 182 10 257 -27z m2284 -673 c0 -92
-1 -95 -38 -148 -73 -105 -207 -166 -344 -157 -82 6 -134 32 -170 84 -78 110
-21 274 104 302 24 6 134 10 246 11 l202 1 0 -93z"/>
</g>
</svg>

    </Box> 
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
